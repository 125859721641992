import React from "react";
import classNames from "classnames";

import styles from "./Page.module.scss";
import { motion, Variants } from "framer-motion";

const standardFade: Variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
};

const Page: React.FC<{ className?: string }> = ({ children, className }) => {
  return (
    <motion.div
      variants={standardFade}
      initial="initial"
      animate="enter"
      className={classNames(styles.page, className)}
    >
      {children}
    </motion.div>
  );
};

export { Page };
