import React from "react";

import { Link } from "gatsby";
import { SEO } from "../components/common/seo";
import { Page } from "../components/Layout/Page";

const NotFoundPage: React.FC = () => (
  <>
    <SEO title="404: Not found" />

    <Page>
      <h1>Page not found</h1>
      <p>Unfortunately it doesn't seem like this page exists.</p>
      <p>
        Use the menu in the top right, or go back to the{" "}
        <Link to="/">homepage</Link>
      </p>
    </Page>
  </>
);

// eslint-disable-next-line import/no-default-export
export default NotFoundPage;
